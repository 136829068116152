<script setup lang="ts">
import { PhCheckSquare, PhSquare } from '@phosphor-icons/vue'

interface Props {
  trueValue?: string | boolean
  falseValue?: string | boolean
  value?: string
  checkSize?: number
}
const props = withDefaults(defineProps<Props>(), {
  trueValue: true,
  falseValue: false,
  value: '',
  checkSize: 20,
})

const model = defineModel<boolean | string | string[]>({ required: true })

function handleInput(e: Event) {
  const target = e.target as HTMLInputElement

  const checked = target.checked

  // HANDLE FOR ARRAY
  if (model.value instanceof Array) {
    const copy = [...model.value]

    if (checked) {
      copy.push(target.value)
    } else {
      copy.splice(copy.indexOf(target.value), 1)
    }

    model.value = copy
  } else {
    // FOR SINGLE VALUES
    const whatToEmit = checked ? props.trueValue : props.falseValue
    model.value = whatToEmit
  }
}

const isChecked = computed(() => {
  if (model.value instanceof Array) {
    return model.value.includes(props.value)
  }

  return model.value === props.trueValue
})
</script>

<template>
  <label class="flex">
    <input
      :value="value"
      class="hidden"
      type="checkbox"
      :checked="isChecked"
      @input="handleInput"
    />
    <span class="flex-shrink-0 cursor-pointer text-primary">
      <PhCheckSquare v-if="isChecked" :size="checkSize" weight="fill" />
      <PhSquare v-else :size="checkSize" />
    </span>
    <slot />
  </label>
</template>
